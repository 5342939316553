

































































































.login-container {
    width: 100vw;
    height: 100vh;

    & > ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }

    .login-wrapper {
        background: url("../assets/image/login_student.png") no-repeat center center;
        background-size: cover;
        min-width: 1100px;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.login-main {
    width: 100%;
    margin-left: 200px;
    overflow: hidden;
    display: flex;

    .login-left {
        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }
    }

    .login-right {
        width: 1%;
        flex: 1;

        .title {
            font-size: 48px;
            font-family: BigruixianRegularGB2;
            color: #FFFFFF;
        }

        .title-text {
            font-size: 24px;
            font-family: BigruixianRegularGB2 .0;
            font-style: italic;
            color: #FFFFFF;
            margin-top: 20px;
            letter-spacing: 8px;
        }

        .demo-ruleForm {
            margin-top: 90px;

            .el-form-item__content {
                color: #fff;
            }
        }

        ::v-deep .el-form-item__label {
            color: #fff;
            font-family: Microsoft YaHei;
            opacity: 0.6;

            &:before {
                content: '';
            }
        }
    }
}

::v-deep.input-student {

    .el-input__inner {
        background-color: #070932;
        padding-left: 60px;
        border-top: none;
        border-left: none;
        border-right: none;
        width: 520px;
        color: #fff;
        font-size: 16px;
    }

    .el-input__prefix {
        left: 10px;
    }

    .iconfont {
        color: #fff;
        font-size: 20px;
    }

    :-webkit-autofill {
        -webkit-text-fill-color: #fff;
        transition: background-color 50000s ease-in-out 0s;
    }
}

.login-button {
    background: #4B45FF;
}
